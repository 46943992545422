import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import * as amplitude from "@amplitude/analytics-browser";
import { SessionContext } from './SessionContext';


export const CommonDataContext = React.createContext();

export function CommonDataProvider(props) {
  const { authenticatedUser, userProfile } = useContext(AuthContext);
  const [servicePoint, setServicePoint] = useState(null); // you can set an initial value or fetch it
  const [servicePointDataIsLoading, setServicePointDataIsLoading] = useState(false);
  const [errorLoadingServicePointData, setErrorLoadingServicePointData] = useState(false);
  const [siteDisplayAddress, setSiteDisplayAddress] = useState('');
  const [sitePostcode, setSitePostcode] = useState(null);
  const { sessionContext, setSessionContext } = useContext(SessionContext);

  var dataIsLoading = false;
  var loadCount = 0;

  useEffect(() => {
    console.log(`From CommonDataContext: Authenticated user profile: ${JSON.stringify(userProfile)}`)
  }, []);


  useEffect(() => {
    if (((userProfile && Object.keys(userProfile).length === 0 && userProfile.constructor === Object) || !userProfile)) return;

    //If no property selected, set it to be the default property
    if (!sessionContext.selectedProperty.accountId) {
      setSessionContext({
        ...sessionContext,
        selectedProperty: {
          accountId: userProfile?.consumerId ? userProfile?.defaultServicePoint?.accountId : undefined,
          servicePointId: userProfile?.consumerId ? userProfile?.defaultServicePoint?.servicePointId : undefined
        }
      });
    }
    
    const consumerId = userProfile?.consumerId;
    const accountId = sessionContext.selectedProperty.accountId; //userProfile?.consumerId ? userProfile?.defaultServicePoint?.accountId : undefined;
    const servicePointId = sessionContext.selectedProperty.servicePointId; //userProfile?.consumerId ? userProfile?.defaultServicePoint?.servicePointId : undefined;

    if (!accountId || !servicePointId) return;

    if (!consumerId) return;
    loadCount++;

    if (loadCount > 1) return;

    if (!servicePointDataIsLoading) {
      setServicePointDataIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}`)
        .then(response => {
          const servicePointData = response.data;
          setServicePoint(servicePointData);
          setErrorLoadingServicePointData(false);

          // Site address from servicePoint data
          var address = "", postCode;
          if (servicePointData?.location.addressUType == "paf") {
            const spLocation = servicePointData.location.paf;
            address = (spLocation.flatUnitType ? spLocation.flatUnitType : '')
              + (spLocation.flatUnitNumber ? spLocation.flatUnitNumber + '/' : '')
              + (spLocation.thoroughfareNumber1 ? spLocation.thoroughfareNumber1 : '')
              + (spLocation.thoroughfareNumber1Suffix ? spLocation.thoroughfareNumber1Suffix + ' ' : ' ')
              + (spLocation.thoroughfareNumber2 ? '- ' + spLocation.thoroughfareNumber2 : '')
              + (spLocation.thoroughfareNumber2Suffix ? spLocation.thoroughfareNumber2Suffix + ' ' : ' ')
              + (spLocation.lotNumber && !spLocation.thoroughfareNumber1 ? spLocation.lotNumber + ' ' : '')
              + toProperCase(spLocation.streetName ? spLocation.streetName + ' ' : '')
              + toProperCase(spLocation.streetType)// ? spLocation.streetType + ', ' : '')
            //+ toProperCase(spLocation.localityName ? spLocation.localityName + ', ' : '')
            //+ (spLocation.postcode ? spLocation.postcode + ', ' : '')
            //+ (spLocation.state || '').toUpperCase();
            postCode = spLocation.postcode;
          } else if (servicePointData?.location.addressUType == "simple") {
            const spLocation = servicePointData.location.simple;
            address = (spLocation.addressLine1 ? spLocation.addressLine1 + ', ' : '')
              + toProperCase(spLocation.addressLine2 ? spLocation.addressLine2 + ', ' : '')
              + toProperCase(spLocation.addressLine3)// ? spLocation.addressLine3 + ', ' : '')
            //+ toProperCase(spLocation.city ? spLocation.city + ', ' : '')
            //+ (spLocation.postcode ? spLocation.postcode + ', ' : '')
            //+ (spLocation.state || '').toUpperCase();
            postCode = spLocation.postcode;
          };

          setSiteDisplayAddress(address);
          setSitePostcode(postCode)
        }).catch(error => {
          console.error('Error fetching service point info:', error);
          setErrorLoadingServicePointData(true);
        })
        .finally(() => {
          setServicePointDataIsLoading(false);
          //stopLoadingSpinner('planBlock');
        });
    }


    //setPlanSortBy(planSortBy); //Trigger the sorting

    function toProperCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }, [userProfile]);

  return (
    <CommonDataContext.Provider value={{ displayAddress: siteDisplayAddress, sitePostcode, userProfile, authenticatedUser, servicePoint }}>
      {props.children}
    </CommonDataContext.Provider>
  );
}