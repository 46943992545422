import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';
import './NewUser.scss'
import { Welcome, WeWorkForYou, Freemium, SupportedStates, OneClickLogin } from '../../components/Onboarding/ValueProps';
import { GetStartedWithBiza } from '../../components/Onboarding/GetStartedWithBiza';
import * as AutomisedAPIs from '../../utils/automisedAPIs';
import { CompletedConsent } from '../../components/Onboarding/CompletedConsent';
import { Collecting } from '../../components/Onboarding/Collecting';
import { SystemError } from '../../components/Onboarding/SystemError';
import { OnboardingFlowComponent } from '../../components/Onboarding/OnboardingFlowComponent';

export const NewUser = () => {
    const { authenticatedUser, userProfile } = useContext(AuthContext);
    const sortAgreementsByDate = (agreementsArray) => {
        const sortedArray = Array.isArray(agreementsArray) && agreementsArray.length > 0
            ? agreementsArray.sort((a, b) => {
                const dateA = new Date(a.details.primed.date);
                const dateB = new Date(b.details.primed.date);
                return dateB - dateA; // For descending order
            })
            : [];

        return sortedArray;
    }
    const activeCdrAgreements = userProfile?.cdrAgreements ? sortAgreementsByDate(Object.values(userProfile.cdrAgreements).filter(agreement => agreement.status === 'ACTIVE')) : false;
    const primedCdrAgreements = userProfile?.cdrAgreements ? sortAgreementsByDate(Object.values(userProfile.cdrAgreements).filter(agreement => agreement.status === 'PRIMED')) : false;
    const flowStepsAr =
        [
            ['getStartedWithBiza', { component: <GetStartedWithBiza />, progressBarStep: undefined }],
            //['getStarted', { component: <GetStarted />, progressBarStep: undefined }],
            //['chooseProvider', { component: <ChooseProvider />, progressBarStep: 'Provider' }],
            //['consent', { component: <Consent />, progressBarStep: 'Consent' }],
            //['connecting', { component: <Connecting />, progressBarStep: 'Connect' }],
            //['confirm', { component: <Confirm />, progressBarStep: 'Confirm' }],
            ['loading', { component: <>Loading...</>, progressBarStep: undefined, backLink: false }],
            ['error-scopes', { component: <>!Error! - Not all required scopes were consented to</>, progressBarStep: undefined, backLink: false }],
            ['error-finalising', { component: <SystemError />, progressBarStep: undefined, backLink: false }],
            ['error-incomplete-consent', { component: <GetStartedWithBiza status="incompleteConsent" />, progressBarStep: undefined, backLink: false }],
            ['finalising', { component: <Collecting />, progressBarStep: undefined, backLink: false }],
            ['success', { component: <CompletedConsent />, progressBarStep: undefined, backLink: false }],
        ]
    const navigate = useNavigate();
    const [selectedProvider, setSelectedProvider] = useState(undefined);
    const [agreementDetails, setAgreementDetails] = useState(undefined);
    const [activeFlowStep, setFlowStep] = useState('loading');

    useEffect(() => {
        async function checkUserState(state) {
            console.debug(`From NewUser: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)
            console.debug(userProfile);
            console.debug(`State id: ${state}`);

            if (userProfile?.cdrAgreements) { //Logic for when a CDR agreement exists
                if (userProfile.status == "PROSPECT") {
                    if (primedCdrAgreements.length > 0) {
                        const targetPrimedCdrAgreement = state ? primedCdrAgreements.find(agreement => agreement.details.requested.data.state === state)?.details?.primed : primedCdrAgreements[0]?.details?.primed;  //Obviously matching on 'state' is ideal, but just in case that is missing we'll take a stab at the first primed agreement
                        setAgreementDetails(targetPrimedCdrAgreement.data);
                        //if (!targetPrimedCdrAgreement) throw new Error(`Bad state value. Could not find correlating agreement.`);
                        console.debug(`User is half-way through onboarding. An agreement has already been primed. Need to check if it has been completed/confirmed or not: ${JSON.stringify(targetPrimedCdrAgreement)}`);
                        const primedAgreementStatus = await AutomisedAPIs.getCdrAgreementStatus(targetPrimedCdrAgreement.data.agreementId);
                        if (primedAgreementStatus.status === "ACTIVE") { //Consent flow was completed and agreement is active. Data can be collected
                            setSelectedProvider(primedAgreementStatus.providerName); //Until now, we don't know which provider the user has chosen. Now we do, so let's set this context variable
                            //Did the user consent to all scopes that are required?
                            if (primedAgreementStatus.scopeCongruence) { //Yes - all scopes selected. Data is good to be collected
                                console.debug(`Consent finalised and ACTIVE. Ready to collect and use data.`)
                                //Kick-off the process of collecting the data, so that it is ready for consumer
                                setFlowStep('finalising');
                                console.debug(`Finalising the onboarding for this user`);
                                AutomisedAPIs.finaliseOnboarding(targetPrimedCdrAgreement.data.agreementId)
                                    .then(() => {
                                        console.debug(`Onboarding succesfully finalised!`)
                                        //Show success step
                                        setFlowStep('success');
                                    })
                                    .catch(error => {
                                        console.error(`Could not finalise onboarding: ${error}`)
                                        //Show error step
                                        setFlowStep('error-finalising')
                                    })
                            } else { //No, there is amismatch between scopes required and scopes consented
                                console.debug(`Incongruent scope consent. Need to show an error page...`)
                                setFlowStep('error-scopes')
                            }
                        } else { //Consent flow was not completed...
                            console.debug(`Consent flow not complete. Need to try again.`)
                            setFlowStep('error-incomplete-consent')
                        }
                    } else { //Profile status of PROSPECT, but no active CDR agreements. Can happen if agreement has been revoked.
                        setFlowStep('getStartedWithBiza');
                    }
                } else if (userProfile.status == "ACTIVE") {
                    if (activeCdrAgreements.length > 0) { //Active consumer + an active agreement; they're good to go. Redirect to dashboard
                        navigate('/');
                    }
                }
            } else if (userProfile) { //Logic for when a logged in user profile exists but no CDR agreement. I.e. they've been here before, but either didn't get an agreement or it has been removed
                setFlowStep('getStartedWithBiza');
            } else { //No user profile. Ie an anonymous user, so start onboarding flow from the top.
                setFlowStep(flowStepsAr[0][0])
            }
        }
        const queryParams = new URLSearchParams(window.location.search);
        const consentFlowStateParam = queryParams.get('state');

        checkUserState(consentFlowStateParam);
    }, []);

    return (OnboardingFlowComponent({ flowStepsAr, activeFlowStep, selectedProvider, setSelectedProvider, primedCdrAgreements, agreementDetails }));
};

export const PreNewUser = () => {
    const flowStepsAr =
        [
            ['valueProp1', { component: <Welcome />, progressBarStep: undefined }],
            ['valueProp2', { component: <WeWorkForYou />, progressBarStep: undefined }],
            ['valueProp3', { component: <Freemium />, progressBarStep: undefined }],
            ['valueProp4', { component: <OneClickLogin />, progressBarStep: undefined }],
            ['valueProp5', { component: <SupportedStates />, progressBarStep: undefined }],
        ]

    return OnboardingFlowComponent({ flowStepsAr });
}
