import { useContext, useEffect, useState } from 'react';
import './ValueProps.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';
import { HintButton } from '../Common/Hints';

const countOfValuePropScreens = 5;

export const Welcome = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "Welcome to Automised Energy!";

    const listItems = [
        'Reduce your electricity bill',
        'Always be on the best plan',
        'Put this life-admin task on auto-pilot'
    ];

    return (
        <ValueProp image="moneyPots.png" imageAlt="Money pots" title={title} listItems={listItems} position="1" />
    )
};

export const WeWorkForYou = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "We've got your back";

    const listItems = [
        '100% independent of all providers',
        'We work for you; no-one else',
        'No ads, no sales calls, no spam'
    ];

    return (
        <ValueProp image="paperPlane.png" imageAlt="Paper aeroplane" title={title} listItems={listItems} position="2" />
    )

};

export const Freemium = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "Get started for free!";

     const listItems = [
        'Free analysis of your current plan',
        'No card required',
        'Subscription available'
    ];

    return (
        <ValueProp image="flower.png" imageAlt="Flower" title={title} listItems={listItems} position="3" />
    )

};

export const SupportedStates = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "Currently supported states";

    const listItems = [
        'New South Wales',
        'Australian Capital Territory'
    ];

    const navigateToLogin = () => {
        window.location.href = '/login';
    };

    const OtherStateOptions = <>
        <p><HintButton label="Not yet active in your state?" topic="otherStateOptions" className="sub" /></p>
    </>

    return (
        <ValueProp image="australia_states_active.png" imageAlt="Email" title={title} listItems={listItems} position="5" customNextHandler={navigateToLogin} customNextLabel="That's me. Let's go!" floorComponent={OtherStateOptions} />
    )

}

export const OneClickLogin = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "Smooth, safe, one-click sign-in";

    const listItems = [
        'One-click sign-in with Google or Facebook',
        'Name and email address are used to create your account',
        'Your information is safe with us'
    ];

    return (
        <ValueProp image="email.png" imageAlt="Email" title={title} listItems={listItems} position="4" />
    )

}

const ValueProp = ({ image, imageAlt, title, listItems, position, customNextHandler, customNextLabel, floorComponent }) => {
    const { flowStep, gotoNextStep, gotoPrevStep, gotoGetStarted } = useContext(OnboardingContext);

    return (
        <div className='flowStepContent'>
            <div className='carouselContent'>
                <img src={`/assets/images/${image}`} className='valuePropImage' alt={imageAlt} />
                <h1 className='headline'>
                    {title}
                </h1>
                <ul>
                    {listItems.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            <div className='carouselProgress'>
                {new Array(countOfValuePropScreens).fill().map((_, index) => {
                    if ((index + 1) == position) {
                        return (
                            <div key={index} className='indicatorDot currentDot' />
                        )
                    } else {
                        return (
                            <div key={index} className='indicatorDot' />
                        )
                    }
                }
                )}
            </div>
            <div className='nav'>
                <button onClick={customNextHandler ?? gotoNextStep} className='primary next'>{customNextLabel ?? "Next"}</button>
                {floorComponent}
                </div>
        </div>
    )
}
