import React, { useContext, useEffect, useState } from 'react';
import './CostBreakdown.scss';
import Icon from '../Common/Icon';
import { formatCentsAsDollars, formatCentsAsDollarsAddGst } from '../../utils/utils';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';


const CostBreakdown = ({ planDetails, eligibilityIsConfirmed }) => {
    console.log(planDetails);
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    const costBreakdown = planDetails.consumerCosts.breakdown;
    const totalExpense = costBreakdown.usage + costBreakdown.supply + costBreakdown.clUsage + costBreakdown.clSupply + costBreakdown.regularFees + (costBreakdown.otherCosts > 0 ? costBreakdown.otherCosts : 0);
    const range = Math.abs(costBreakdown.usage) + Math.abs(costBreakdown.supply) + Math.abs(costBreakdown.clUsage) + Math.abs(costBreakdown.clSupply) + Math.abs(costBreakdown.export) + Math.abs(costBreakdown.otherCosts);
    const largestCost = Math.max(Math.abs(costBreakdown.usage), Math.abs(costBreakdown.supply), Math.abs(costBreakdown.clUsage), Math.abs(costBreakdown.clSupply), Math.abs(costBreakdown.export), Math.abs(costBreakdown.otherCosts + costBreakdown.regularFees))

    return (
        <div className='cost-breakdown'>
            <CostLineItem amount={costBreakdown.usage} percentage={costBreakdown.usage / totalExpense} className="import" category="General usage" width={Math.abs(costBreakdown.usage) / largestCost * 100} />
            <CostLineItem amount={costBreakdown.supply} percentage={costBreakdown.supply / totalExpense} className="supply" category="General supply" width={Math.abs(costBreakdown.supply) / largestCost * 100} />
            {planDetails.electricityContract.controlledLoad && (
                <>
                    <CostLineItem amount={costBreakdown.clUsage} percentage={costBreakdown.clUsage / totalExpense} className="cl-import" category="Controlled load usage" width={Math.abs(costBreakdown.clUsage) / largestCost * 100} />
                    <CostLineItem amount={costBreakdown.clSupply} percentage={costBreakdown.clSupply / totalExpense} className="cl-supply" category="Controlled load supply" width={Math.abs(costBreakdown.clSupply) / largestCost * 100} />
                </>
            )}
            <CostLineItem amount={costBreakdown.export} percentage={costBreakdown.export / totalExpense} className="export" category="Credits" width={Math.abs(costBreakdown.export) / largestCost * 100} />
            <CostLineItem amount={costBreakdown.otherCosts + costBreakdown.regularFees} percentage={(costBreakdown.otherCosts + costBreakdown.regularFees) / totalExpense} className="other" category="Add'l fees" width={Math.abs((costBreakdown.otherCosts + costBreakdown.regularFees)) / largestCost * 100} />
            <hr />
            <CostLineItem amount={planDetails.consumerCosts.total} category="Total" className="total" />
            {planDetails.planId != 'current_plan' &&
                <div className='inlineCtaGroup'>
                            {eligibilityIsConfirmed ?
                                <Button type="primary" className={`switch-now`} label="Switch now" href="#switch-via-email" clickHandler={() => setSelectedItem('switch-now')} trackingId={`switch-via-email`} />
                                :
                                <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} trackingId={`check-eligibility`} />
                            }
                 </div>
            }
        </div>
    );
};

const CostLineItem = ({ amount, category, width, percentage, className }) => {
    var directionLabel = (amount < 0) ? 'Debit' : 'Credit';

    if (amount == 0) return;

    return (
        <div className={`line-item ${className}`}>
            <div className="proportion-chart">
                <div className={`proportion-bar ${className}`} style={{ width: width + '%' }}>
                    <span className='cost-category'>
                        {category}
                    </span>
                    {category != 'Total' &&
                        <span className='proportion-percentage'>
                            {(percentage * 100).toFixed(0)}%
                        </span>
                    }
                </div>
            </div>
            <div className='cost-direction'>
                <Icon name={`${amount < 0 ? 'Minus' : 'Plus'}`} className="directionIndicator" descriptiveLabel={directionLabel} />
            </div>
            <span className='currency'>
                $
            </span>
            <span className='cost-amount'>
                {formatCentsAsDollars(Math.abs(amount), true)}
            </span>

        </div>
    )
}


export default CostBreakdown;