// contexts/GTMContext.js
import { createContext, useContext, useEffect } from 'react'
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

const GTMContext = createContext(null)

const analytics = Analytics({
  app: 'your-app-name',
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTM_ID
    })
  ]
})

export function GTMProvider({ children }) {
  useEffect(() => {
    // Initialize page tracking
    analytics.page()
  }, [])

  const trackEvent = (eventName, properties = {}) => {
    // Optional: Add common properties
    const enrichedProperties = {
      ...properties,
      timestamp: new Date().toISOString(),
      page_path: window.location.pathname
    }
    
    analytics.track(eventName, enrichedProperties)
  }

  return (
    <GTMContext.Provider value={{ trackEvent }}>
      {children}
    </GTMContext.Provider>
  )
}

// Custom hook to use in components
export function useGTM() {
  const context = useContext(GTMContext)
  if (!context) {
    throw new Error('useGTM must be used within GTMProvider')
  }
  return context
}