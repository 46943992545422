import { useEffect, useState } from "react";
import { OnboardingContext } from "../../utils/OnboardingContext";
import OnboardingBaseView from "./OnboardingBaseView";

export const OnboardingFlowComponent = ({ flowStepsAr, activeFlowStep, selectedProvider, setSelectedProvider, primedCdrAgreements, agreementDetails }) => {
    const flowStepsMap = new Map(flowStepsAr);

    const progressBarSteps = flowStepsAr
        .map(step => step[1]?.progressBarStep) // Extract progressBarStep from each value
        .filter(progressBarStep => progressBarStep !== undefined); // Filter out undefined values

    const flowStepIdsAr = flowStepsAr
        .map(step => step[0]);

    const [flowStep, setFlowStep] = useState(activeFlowStep ?? flowStepIdsAr[0]);
    const [inProp, setInProp] = useState(false);

    const gotoDashboard = () => {
        window.location.href = '/';
    }

    const gotoNextStep = () => {
        let indexOfCurrentFlowStep = flowStepIdsAr.indexOf(flowStep);
        if ((indexOfCurrentFlowStep + 1) == flowStepIdsAr.length) { //At end of flowSteps. At last step. No more steps to go to.
            return;
        }
        //Update context to be the next step
        setInProp(false);
        setFlowStep(flowStepIdsAr[indexOfCurrentFlowStep + 1]);
    }

    const gotoPrevStep = () => {
        let indexOfCurrentFlowStep = flowStepIdsAr.indexOf(flowStep);
        if (indexOfCurrentFlowStep == 0) { //At start of flowSteps. At first step. No more steps to go to.
            return;
        }
        //Update context to be the next step
        setInProp(false);
        setFlowStep(flowStepIdsAr[indexOfCurrentFlowStep - 1]);
    }

    const gotoGetStarted = () => {
        setInProp(false);
        setFlowStep('getStarted');
    }

    useEffect(() => {
        if (!activeFlowStep) {
            return;
        }
        console.debug(`Setting flow step to: ${activeFlowStep}`);
        setFlowStep(activeFlowStep);
    },[activeFlowStep]);

    return (
        <OnboardingContext.Provider value={{ flowStepIdsAr, flowStep, currentFlowStepConf: flowStepsMap.get(flowStep), gotoDashboard, gotoNextStep, gotoPrevStep, gotoGetStarted, progressBarSteps, currentProgressBarStep: flowStepsMap.get(flowStep).progressBarStep, selectedProvider, setSelectedProvider, inProp, setInProp, primedCdrAgreements, agreementDetails }}>
            <OnboardingBaseView>
                {flowStepsMap.get(flowStep).component}
            </OnboardingBaseView>
        </OnboardingContext.Provider>
    )

}