import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import HeroSummary from '../components/Dashboard/HeroSummaryDash';
import TailoredSuggestions from '../components/Dashboard/TailoredSuggestions';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import axios from 'axios';
import { DataProvider, DataContext } from '../utils/DashboardDataContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataProvider } from '../utils/CommonDataContext';

const Dashboard = () => {
  const { authenticatedUser, userProfile } = useContext(AuthContext);
  //console.debug(`authenticatedUser: ${authenticatedUser}, up: ${userProfile}`)

  if (authenticatedUser && ((userProfile && Object.keys(userProfile).length > 0))) {
    return (
      <Site>
        <CommonDataProvider>
          <DataProvider>
            <PageHeaderBar />
            <HeroSummary />
            <TailoredSuggestions />
          </DataProvider>
        </CommonDataProvider>
        <PageFooter />
      </Site>
    );
  } else {
    return (
      <Site>
        Authenticating
        <PageFooter />
      </Site>
    );
  }
};



export default Dashboard;