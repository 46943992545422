import React, { useContext, useEffect, useState } from 'react';
import { CommonDataContext } from '../../utils/CommonDataContext';
import { Buffer } from 'buffer';
import './SubscriptionContent.scss';
import { StripePricingTableForExistingCustomer, StripePricingTableForNewCustomer } from '../Common/StripePricingTable';
import StripeBuyButton from '../Common/StripeBuyButton';
import { getBillingPortalURL, startStripeCustomerSession } from '../../utils/automisedAPIs';
import Button from '../Common/Button';
import Icon from '../Common/Icon';

const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }).replace(/ /g, '-');
};

/**
 * client_reference_id can be composed of alphanumeric characters, dashes, or underscores, and be any value up to 200 characters. Invalid values are silently dropped, but your payment page continues to work as expected.
 * 
 * @param {*} string 
 * @returns 
 */
function stripeEncode(string) {
    const ENCODE_PREFIX = 'ENC-';

    // Encode the string to Base64
    let base64Encoded = Buffer.from(string).toString('base64');

    // Replace Base64 URL unsafe characters to make it Stripe compatible
    base64Encoded = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    // Add prefix to indicate the string is encoded
    base64Encoded = ENCODE_PREFIX + base64Encoded;

    return base64Encoded;
}

export const SubscriptionContent = () => {
    const { userProfile, authenticatedUser } = useContext(CommonDataContext)
    const [billingPortalURL, setBillingPortalURL] = useState(null);
    const [stripeCustomerSession, setStripeCustomerSession] = useState(null);
    const stripeProfile = userProfile?.stripeProfile;
    let subscriptionExpiresAt, isSubscribed = false, currentSubscriptionStatusLabel = 'Not Subscribed', subStartDate, subEndDate;
    if (stripeProfile) {
        subscriptionExpiresAt = new Date(stripeProfile?.subscription?.endDate);
        console.log(`Subscription expires at: ${subscriptionExpiresAt}`)
        isSubscribed = stripeProfile.status === 'active';
        currentSubscriptionStatusLabel = isSubscribed ? 'Subscribed' : 'You are currently not subscribed. Unlock bigger savings and rest-assured by subscribing for 12 months:';
        subStartDate = formatDate(stripeProfile.subscription?.startDate)
        subEndDate = formatDate(stripeProfile.subscription?.endDate)
    }
    const email = authenticatedUser.attributes.email;
    const stripeReferenceId = stripeEncode(userProfile?.consumerId);
    //console.debug(`Stripe stripeReferenceId: ${stripeReferenceId}`)
    //console.debug(`Email: ${email}`)

    //Generate Stripe billing portal URL & customer session if user already has a Stripe customer ID
    useEffect(() => {
        if (!userProfile || !userProfile?.stripeProfile?.customerId) return;

        //If we don't have a billing portal URL yet, let's get one
        if (!billingPortalURL) {
            getBillingPortalURL(userProfile?.consumerId)
                .then((response) => { setBillingPortalURL(response); })
                .catch((error) => { console.error(`Unable to fetch billing portal URL: ${error}`) })
        }

        //If we don't have a customer session yet, let's get one
        if (!stripeCustomerSession) {
            startStripeCustomerSession(userProfile?.consumerId)
                .then((response) => { setStripeCustomerSession(response); })
                .catch((error) => { console.error(`Unable to start Stripe customer session: ${error}`) })
        }
    }, [userProfile])


    useEffect(() => {
        //If
    }, [billingPortalURL])

    return <>
        <div className='profile-tile your-subscription content-tile'>
            <h1>Automised Energy Subscription</h1>
            <div className="profile-section">
                <span className='profile-value'>{isSubscribed && <Icon name="tick-circle" descriptiveLabel="subscribed" />} {currentSubscriptionStatusLabel} {isSubscribed && <span className='sub-value'>(to {subEndDate})</span>} </span>
                <div className="profile-section-content">
                    <div className="profile-section-content-part">
                        {!isSubscribed && stripeCustomerSession?.client_secret && <StripePricingTableForExistingCustomer referenceId={stripeReferenceId} clientSecret={stripeCustomerSession?.client_secret} className='stripe-susbcribe profile-action' />}
                        {!isSubscribed && !stripeCustomerSession?.client_secret && <StripePricingTableForNewCustomer referenceId={stripeReferenceId} email={email} className='stripe-susbcribe' />}
                        {isSubscribed && <div className='profile-action'><Button href={billingPortalURL?.portalUrl} label="Manage subscription" type="secondary" trackingId={`manage-subscription`}/></div>}
                    </div>
                </div>
            </div>
        </div>
    </>
}