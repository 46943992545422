import Icon from "./Icon"
import { useContext } from 'react';
import { useGTM } from '../../utils/AnalyticsContext';

const Button = ({ type, id, icon, label, clickHandler, href, children, selected, className, enabled = true, disabledHref, target, trackingId, doNotTrack, alsoTrack }) => {
    const { trackEvent } = useGTM();

    const handleClick = (event) => {
        if (trackEvent) {
            console.debug(`Tracking button click event: ${trackingId ?? label ?? String(children)}`);
            let propsToTrack = {
                trackingId,
                label,
                children: String(children),
                type,
                href,
                id,
                icon,
                ...alsoTrack
            };
            //Delete all properties of propsToTrack that exist in the array doNotTrack
            if (Array.isArray(doNotTrack)) {
                doNotTrack.forEach((prop) => {
                    delete propsToTrack[prop];
                });
            };
            //Track the event
            trackEvent('button_click', propsToTrack)
        }
        if (clickHandler) {
            clickHandler(event);
        }
    };

    return (
        <a className={`button ${type} ${className ? className : ''} ${selected ? 'selected' : ''} ${enabled ? 'enabled' : 'disabled'}`} id={id} onClick={handleClick} href={enabled ? href : disabledHref} target={target ? target : ''}>
            {icon ? <Icon name={icon} className={`icon ${icon}`} descriptiveLabel={`Icon for ${icon}`} /> : ''}
            {label || children}
        </a>
    )
}

export default Button