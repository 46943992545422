import React from 'react';
import './PageFooter.scss';

const PageFooter = () => {
    return (
        <div id="pageFooter">
            <span id="copywrite">© 2025 Automised Energy. All rights reserved</span>
        </div>
    );
};

export default PageFooter;