import { useContext, useEffect, useState } from 'react';
import './GetStarted.scss'
import { OnboardingContext } from '../../utils/OnboardingContext';
import { HintButton } from '../Common/Hints';
import * as AutomisedAPIs from '../../utils/automisedAPIs';

import { AuthContext } from '../../utils/AuthContext';

export const GetStartedWithBiza = ({ status }) => {
    const { flowStep, gotoNextStep, gotoPrevStep, progressBarSteps } = useContext(OnboardingContext);
    const [ctaBusy, setCtaBusy] = useState(false)
    const [consentError, setConsentError] = useState(undefined);
    const { authenticatedUser } = useContext(AuthContext);
    const [dhInfo, setDhInfo] = useState({ brandName: 'Retailer', logoUri: undefined });
    const [byLine, setByLine] = useState(``)
    const [title, setTitle] = useState(`OK - here are the steps${authenticatedUser.attributes.name ? `, ${authenticatedUser.attributes.name}` : ''}:`)
    const [button, setButton] = useState(`Get started`)

    useEffect(() => {
        // Check for error parameter in URL
        const queryParams = new URLSearchParams(window.location.search);
        const errorParam = queryParams.get('error');

        if (errorParam) {

            const errorObj = {
                label: errorParam,
                description: queryParams.get('errorDescription'),
                uri: queryParams.get('errorUri')
            }
            setConsentError(errorObj);

            setTitle(`Error getting permissions`);
            setByLine(`There's been an error while getting your permissions (error description: ${errorObj.description}). Not to worry, let's try again. Here are the steps:`);
            setButton(`Try again`);
        } else { //No error found
            if (status === 'incompleteConsent') {
                setTitle(`Welcome back${authenticatedUser.attributes.name ? `, ${authenticatedUser.attributes.name}` : ''}!`);
                setByLine(`It looks like we haven't got your permission yet. No worries, let's pick up where we left off. Here are the steps:`);
                setButton(`Let's go!`);
            }
        }
    }, []);

    const launchCdrConsentFlow = () => {
        setCtaBusy(true);

        //init agreement
        const newAgreement = AutomisedAPIs.initCdrAgreement()
            .then((newAgreementDetails) => {
                console.debug(`New agreement details: ${JSON.stringify(newAgreementDetails)}`)
                //redirect to agreement flow url
                const redirectUri = newAgreementDetails.data.redirectUri;
                //Redirect here:
                window.location.href = redirectUri;
            })
            .catch((error) => {
                setCtaBusy(false);
            })
            .finally(() => {
                //setCtaBusy(false);
            })
    }

    return (
        <div className='flowStepContent'>
            <div className='heading'>
                <h1 className='headline'>{title}</h1>
                <span className='byline'>{byLine}</span>
            </div>
            <div className='getStartedSteps'>
                <div className='getStartedStep'>
                    <span className='stepNumber'>1.</span>
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            You give permission to us
                        </h2>
                        <p className='stepDescription'>
                            to collect and analyse your data for you
                        </p>
                    </div>
                </div>
                <div className='getStartedStep'>
                    <span className='stepNumber'>2.</span>
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            You give permission to your provider
                        </h2>
                        <p className='stepDescription'>
                            to share your data with us
                        </p>
                    </div>
                </div>
                <div className='getStartedStep'>
                    <span className='stepNumber'>3.</span>
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            We match you with the best plans
                        </h2>
                        <p className='stepDescription'>
                            available to you from all providers, showing you how much you could save
                        </p>
                    </div>
                </div>
            </div>
            <div className='cdrMention'>
                <p className='message'>
                    Secure data sharing via the Consumer Data Right (CDR)
                    <HintButton topic='cdrConsent' />
                </p>
            </div>
            <div className='nav'>
                <button onClick={launchCdrConsentFlow} className={`primary next ${ctaBusy ? 'busy' : ''}`}>{button}</button>
            </div>
        </div>
    )
};