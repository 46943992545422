import React, { useEffect } from 'react';
import Button from './Button';

const StripeBuyButton = ({type}) => {

  return (
    <Button href="https://buy.stripe.com/test_14k5oh7NC68G2Xu9AA?prefilled_email=foo@bar.com" className={`button ${type ?? 'primary'}`} trackingId={`susbcribe`}>
      Subscribe
    </Button>
  );
};

export default StripeBuyButton;