import Site from "../../layouts/Site"
import PageFooter from "./PageFooter"

export const ProcessingSpinnerSite = ({ processingAction }) => {
    return (
        <Site className="processing">
            <ProcessingSpinnerContentTile processingAction={processingAction} />
            <PageFooter />
        </Site>
    )
}

export const ProcessingSpinnerContentTile = ({ processingAction }) => {
    return (
        <div className='content-tile'>
            <ProcessingSpinnerTileContent processingAction={processingAction} />
        </div>
    )
}

export const ProcessingSpinnerTileContent = ({ processingAction }) => {
    return (
        <div className="processing-spinner">
            <span>{processingAction}<span className='flashing-ellipsis' /></span>

            <div className='loader slow' />
        </div>
    )
}